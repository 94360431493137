#root {
  width: 100%;
}

.App {
  position: relative;
}

.main-container {
  width: 100%;
  padding: 24px;
}

.btn-rate {
  border: 1px solid black;
  margin: 3px;
  padding: 0 8px;
  /* width: calc(100% / 10 ); */
  background: 0;
  border-radius: 8px;
  font-size: 30px;

  &[data-active='true'] {
    background: #00b894;
    color: white;
  }
}

.modal {
  margin: auto;
  /* padding: 24px; */
  border-radius: 8px;
  min-width: 300px;
  min-height: 100vh;
  background: white;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); */
  margin-bottom: 24px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* background: linear-gradient(to top, #56e2d7 0%, #58cff1 100%); */
}
.wrapper {
  background: #fff;
  padding: 20px;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.wrapper form {
  height: 55px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
form i {
  position: absolute;
  width: 55px;
  text-align: center;
  font-size: 23px;
  color: #c4c4c4;
  pointer-events: none;
}
form input:valid ~ i {
  color: #58cff1;
}
form input {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 50px 0 45px;
  font-size: 20px;
  caret-color: #58cff1;
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: all 0.1s ease;
}
form input::selection {
  color: #fff;
  background: #58cff1;
}
form input:focus,
form input:valid {
  border-color: #58cff1;
}
form input::placeholder {
  color: #c4c4c4;
}
form .counter {
  position: absolute;
  right: 3px;
  width: 55px;
  font-size: 20px;
  color: #c4c4c4;
  text-align: center;
  border-left: 1px solid #d8d8d8;
  pointer-events: none;
}
form input:valid ~ .counter {
  color: #58cff1;
  border-color: #58cff1;
}

.user {
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  background: #f4f4f4;
  margin-bottom: 8px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.user.gold {
  background: gold;
}

.user p {
  font-size: 12px;
  color: #333;
  display: flex;
  align-items: center;
}

.user:hover {
  background: #ececec;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.button-container {
  margin-top: 25px;
  text-align: center;
}

.button-container > * {
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
  margin-bottom: 8px;
  padding: 6px 6px;
  background: white;
  border-radius: 12px;
  border: solid 1px #0b1115;
}

.scores-game-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
  color: gray;
}

.scores-user li {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.scores-user li p {
  flex: 1;
}

.scores-user li button {
  width: 50px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 2px;
  font-weight: bold;
  padding: 5px;
  opacity: 1;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  border: 1px solid lightgray;
}

.scores-user li button:hover {
  opacity: 0.75;
}
.scores-user li button.active {
  opacity: 1;
  border: 0;
}
.scores-user li button.active.first {
  background-color: gold;
}

.scores-user li button.long {
  width: 150px;
}

.scores-user li button.active.second {
  background-color: silver;
}

.scores-user li button.active.third {
  background-color: #cd7f32;
  color: white;
}

.planning {
  margin-bottom: 50px;
}

.ranking-page,
.game-page,
.petanque-page,
.game-selection-page,
.planning-page,
.hister-page,
.list-user-page {
  max-width: 700px;
  margin: auto;
  margin-bottom: 50px;
}

.ranking-page-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.ranking-section-title,
.game-selection-title,
.favorite-section-title {
  font-weight: 700;
  font-size: 20px;
  width: 100%;
}

.planning-section-title,
.user-section-title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 25px;
}

.planning-item {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 100px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  margin-bottom: 12px;
  color: white;
  position: relative;
}

.planning-item.court {
  background-color: #fff;
  color: black;
}

p.date {
  padding: 24px 0 8px;
  /* font-weight: bold; */
  text-align: center;
}

.planning-item.loup {
  background-color: #90a4ae;
}

.planning-item.petanque {
  background-color: rgba(216, 99, 99, 0.798);
}

.planning-item span {
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  position: absolute;
  top: 12px;
  right: 8px;
}

.planning-item.court span {
  display: none;
}

.planning-item p {
  flex: 1;
}

.planning-item p b {
  margin-right: 4px;
}

.planning-item .score {
  color: white;
  text-align: right;
}

.score-b {
  color: black;
  text-align: right;
}

.greeting-message {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}

.score-container {
  display: flex;
  margin-bottom: 50px;
}

.score-card,
.ranking-card {
  padding: 10px;
  border-radius: 8px;
  width: 50%;
}

.score-card {
  background: #e74c3c;
  margin-right: 2px;
}

.ranking-card {
  background: #f0ac2d;
  margin-left: 2px;
}

.score-card,
.ranking-card {
  color: white;
  font-weight: 500;
}

.score-card-value,
.ranking-card-value {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}

.scoreboard-row-current-player,
.scoreboard-row-other-player {
  display: grid;
  padding-top: 10px;
  padding-bottom: 10px;
  grid-template-columns: 1fr 3fr 1fr;
  grid-row-gap: 50px;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.scoreboard-row-current-player {
  background: #00b894;
  color: white;
  font-weight: 700;
}

.scoreboard-row-rank {
  text-align: center;
}

.scoreboard-row-player-name {
  text-align: left;
}

.scoreboard-row-current-player > .scoreboard-row-score {
  text-align: center;
}

.scoreboard-row-other-player > .scoreboard-row-score {
  text-align: center;
  color: rgb(150, 150, 150);
}

.active {
  background: #00b894;
  color: white;
  border: 1px solid #00b894;
}

.back_btn {
  font-size: 25px;
  margin-right: 20px;
}

.notation {
  margin-top: 24px;
  margin-bottom: 4px;
  padding: 8px;
  /* border: 1px solid grey; */
  border-radius: 8px;
  background: #00b894;
  color: white;
  font-weight: bold;
}

.adminName {
  float: left;
  font-style: italic;
}

.navbar {
  display: flex;
  height: 60px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.navbar-tab {
  font-size: 14px;
  text-align: center;
  justify-content: center;
  width: 50%;
  display: grid;
  color: black;
}

.active-tab {
  color: #00b894;
}

.navbar-tab:hover {
  color: #00b894;
}

.navbar-tab-icon {
  font-size: 25px;
  margin: auto;
}

.loader {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

span[class^='dot-'] {
  opacity: 0;
}
.dot-one {
  font-size: 35px;
  font-weight: 700;
  color: #00b894;
  animation: dot-one 2s infinite linear;
}
.dot-two {
  font-size: 35px;
  font-weight: 700;
  color: #00b894;
  animation: dot-two 2s infinite linear;
}
.dot-three {
  font-size: 35px;
  font-weight: 700;
  color: #00b894;
  animation: dot-three 2s infinite linear;
}
@keyframes dot-one {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.game-selection-header {
  text-align: center;
  margin-bottom: 20px;
}

.game-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.game-item {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 10px;
  transition: border-color 0.3s;
}

.game-item.selected {
  border-color: blue;
}

.game-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.game-title {
  text-align: center;
  margin-top: 5px;
}

.selected-games {
  margin-top: 20px;
}

.selected-games-title {
  font-weight: bold;
}

.selected-games ul {
  list-style-type: none;
  padding: 0;
}

/* Existing styles */
.selected-games ul {
  list-style-type: none;
  padding: 0;
}

.petanque-page-header {
  text-align: center;
  margin-bottom: 20px;
}

.scoreboard-row-other-player {
  background-color: #f8d7da;
}

.scoreboard-row-rank,
.scoreboard-row-player-name,
.scoreboard-row-score {
  display: inline-block;
  margin: 5px;
}

/* New styles for championship UI */
.championship-section {
  margin-top: 40px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.championship-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.groups,
.semi-finals,
.finals {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.group,
.semi-final-player,
.final-player {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.group h4 {
  margin-bottom: 10px;
}

.group-player {
  margin-left: 20px;
}

@media (min-width: 768px) {
  .groups,
  .semi-finals,
  .finals {
    flex-direction: row;
    justify-content: space-around;
  }

  .group,
  .semi-final-player,
  .final-player {
    width: 30%;
  }
}

.user-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.user-item {
  cursor: pointer;
  border: 2px solid transparent;
  padding: 10px;
  transition: border-color 0.3s;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
  width: 150px;
}

.user-item.selected {
  border-color: gold;
}

.user-name {
  font-size: 16px;
  font-weight: bold;
}

.me {
  font-weight: bold;
}

.winner {
  font-weight: bold;
}

.team-info {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 30px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.player {
  background-color: #e0e0e0;
  border-radius: 4px;
  padding: 5px 10px;
}

.player.me {
  background-color: #4caf50;
  color: white;
}

.match-list {
  list-style-type: none;
  padding: 0;
}

.match {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.match-date {
  font-weight: bold;
  margin-bottom: 5px;
}

.match-teams {
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left-team {
  text-align: left;
  border-right: 1px solid #ddd;
}

.right-team {
  text-align: right;
}

.right-team > p,
.left-team > p {
  height: 75px;
}

.match-score {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.match-score-edit {
  margin-top: 20px;
  margin-bottom: 10px;
  display: grid;
  align-items: center;
  justify-items: center;
}

.match-score-edit-input {
  display: flex;
  align-items: center;
  justify-items: center;
}

.match-score-edit-input-btn {
  text-align: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: none;
}

.match-score-edit-input-btn-plus {
  background-color: #4caf50;
  color: white;
}

.match-score-edit-input-btn-minus {
  background-color: #f44336;
  color: white;
}

.match-score-edit-input-value {
  width: 32px;
  height: 32px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.match-score-save-btn {
  width: 100px;
  background-color: #f0ac2d;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  text-align: center;
  margin: auto;
}

.winner {
  color: #4caf50;
}

.ranking-list {
  list-style-type: none;
  padding: 0;
}

.hitster-team-member,
.ranking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.rank {
  font-weight: bold;
  min-width: 30px;
}

.team {
  flex-grow: 1;
  margin: 0 15px;
}

.score {
  font-weight: bold;
}

.game-selection-page {
  max-width: 100%;
  padding: 1rem;
}

.game-selection-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.game-selection-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.game-selection-subtitle {
  font-size: 1rem;
  color: #666;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 1rem;
}

.game-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.game-card:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.game-card.selected {
  box-shadow: 0 0 0 2px #4caf50;
}

.game-image-container {
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(76, 175, 80, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  font-size: 2rem;
  color: #fff;
}

.game-info {
  padding: 0.5rem;
}

.game-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-year {
  font-size: 0.8rem;
  color: #666;
}

@media (min-width: 768px) {
  .game-selection-page {
    padding: 2rem;
  }

  .game-selection-title {
    font-size: 2.2rem;
  }

  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1.5rem;
  }

  .game-title {
    font-size: 1.1rem;
  }

  .game-year {
    font-size: 0.9rem;
  }
}

.game-description {
  font-size: 14px;
}

/* ... existing styles ... */

.favorite-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.favorite-page-header {
  text-align: center;
  margin-bottom: 2rem;
}

.favorite-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.favorite-subtitle {
  font-style: italic;
  color: #666;
}

.user-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.user-item {
  background-color: #fff;
  color: #333;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-item.selected {
  background-color: #4a90e2;
  color: #fff;
}

.user-name {
  margin: 0;
  font-weight: 500;
}

.tournament-player-selection {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tournament-title {
  font-size: 1.75rem;
  color: #2c3e50;
  text-align: center;
}

.player-selection-title {
  font-size: 1.4rem;
  color: black;
  text-align: center;
  margin-bottom: 1rem;
}

.player-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 0.5rem;
}

/* Update the User component styles */
.user-card {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.user-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.user-role {
  font-size: 0.9rem;
  color: #7f8c8d;
}

.user-winner {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.trophy-icon {
  font-size: 1.2rem;
  color: #f1c40f;
  margin-right: 0.5rem;
}

.winner-year {
  font-size: 0.9rem;
  font-weight: bold;
  color: #2ecc71;
}
.planning-item {
  position: relative;
  overflow: hidden;
}

.planning-item img {
  object-fit: cover;
  position: absolute;
  top: 14px;
  right: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* opacity: 0.1; */
}

.hitster-team-info,
.hitster-team-members-container {
  padding-top: 20px;
}

.loup-garou-card {
  width: 100%;
}

.loup-garou-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.logo {
  height: 50px;
  border-radius: 8px;
  margin-right: 10px;
}

.logo2 {
  height: 30px;
  border-radius: 8px;
  margin-right: 10px;
}

.logo-container {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: 25px;
}
